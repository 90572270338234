<template>
  <v-data-table
    dense
    width="100%"
    :headers="headers"
    :items="ccs"
    class="elevation-1"
    sort-by="correlationCoefficent"
    :sort-desc="true"
  >
  
      <template v-slot:item.updateTime="{ item }">
        {{ new Date(item.updateTime).toLocaleString() }}
      </template>

  </v-data-table>
</template>

<script>
export default {
  name: "CCList",

  props: {
    ccs: Array,
  },

  data() {
    return {
      headers: [
        { text: "Symbol", value: "symbol" },
        { text: "Symbol Secondary", value: "symbolSecondary" },
        { text: "Correlation Coefficent", value: "correlationCoefficent" },
        { text: "Update Time", value: "updateTime" },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
