<template>
  <v-container dark id="dashboard" fluid tag="section">
    <v-divider />

      <v-row>
        <v-col cols='12'>
          <cc-panel />
        </v-col>
      </v-row>

      <v-row v-for="symbolItem in show_data" :key="symbolItem.symbol">
        <v-col cols='12'>
          <v-row>{{symbolItem.symbol}}</v-row>
          <v-row>
            <v-col v-for="singleChartData in symbolItem.data" :key="singleChartData.name"
              cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <v-row>{{singleChartData.name}}</v-row>
                <v-row>
                  <v-col cols='12'>
                    <my-chart :options="singleChartData.chartOptions"></my-chart>
                  </v-col>
                </v-row>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import MyChart from "@/components/base/Chart";

import CcPanel from "@/views/dashboard/component/CCPanel";

// import { global_market } from "@/mock_data";

import { get_global_market_info, } from "@/api";

import { gen_global_show_data, } from '@/utils';

export default {
  name: "Dashboard",

  components: {
    MyChart,
    CcPanel,
  },

  mounted: function () {

    get_global_market_info().then((global_market_info) => {
      this.show_data = gen_global_show_data(global_market_info)
    }).catch((reason) => {
      console.error(reason)
    })

  },
  watch: {
  },
  data() {
    return {
      show_data: [],
    };
  },

  methods: {
  }
};
</script>
