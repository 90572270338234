<template>
  <v-tabs>
    <v-tab key="btc">BTC</v-tab>
    <v-tab key="eth">ETH</v-tab>

    <v-tab-item key="btc">
      <cc-list :ccs="btc_ccs"> </cc-list>
    </v-tab-item>

    <v-tab-item key="eth">
      <cc-list :ccs="eth_ccs"> </cc-list>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import CcList from "@/views/dashboard/component/CCList";

import { get_ccs } from "@/api";

export default {
  name: "SfAccountPanel",

  components: {
    CcList,
  },

  watch: {},

  mounted: function () {
    get_ccs()
      .then((ccs) => {
        if (ccs !== null && ccs !== undefined) {
          this.btc_ccs = ccs.filter((item) => item.correlationCoefficent !== 'NaN' && item.symbol === "BTCUSDT");
          this.eth_ccs = ccs.filter((item) => item.correlationCoefficent !== 'NaN' && item.symbol === "ETHUSDT");
        }
      })
      .catch((reason) => {
        console.error(reason);
      });
  },

  data() {
    return {
      btc_ccs: [],
      eth_ccs: [],
      timer: -1,
    };
  },
};
</script>
